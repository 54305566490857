import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

export enum ERoutesName {
  HOME = "home",
  NOT_FOUND = "not-found",
  NO_DATA_RESULT = "no-data-result",
  DIARY = "diary",
  VIEW_IMAGE = "view-image",
  DISEASE = "disease",
  DISEASE_DETAIL = "disease-detail",
  MORE_INFO = "more-info",
  MORE_INFO_DETAIL = "more-info-detail",
  DIARY_DETAIL = "diary-detail",
}

export const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: () => import("@/layouts/client/index.vue"),
    children: [
      {
        path: "/",
        name: ERoutesName.HOME,
        component: () => import("@/pages/home/index.vue"),
      },
      {
        path: "/disease",
        name: ERoutesName.DISEASE,
        component: () => import("@/pages/disease/index.vue"),
      },
      {
        path: "/diary",
        name: ERoutesName.DIARY,
        component: () => import("@/pages/diary/index.vue"),
      },
      {
        path: "/diary/:id",
        name: ERoutesName.DIARY_DETAIL,
        component: () => import("@/pages/diary/id/index.vue"),
      },
      {
        path: "/disease/:id",
        name: ERoutesName.DISEASE_DETAIL,
        component: () => import("@/pages/disease/id/index.vue"),
      },
      {
        path: "/more-info",
        name: ERoutesName.MORE_INFO,
        component: () => import("@/pages/moreInfo/index.vue"),
      },
      {
        path: "/more-info/:id",
        name: ERoutesName.MORE_INFO_DETAIL,
        component: () => import("@/pages/moreInfo/id/index.vue"),
      },
    ],
  },
  {
    path: "/image-view",
    name: ERoutesName.VIEW_IMAGE,
    component: () => import("@/pages/imageView/index.vue"),
  },
  {
    path: "/no-data-result",
    name: ERoutesName.NO_DATA_RESULT,
    component: () => import("@/pages/noDataResult/index.vue"),
  },
  {
    path: "/:catchAll(.*)",
    name: ERoutesName.NOT_FOUND,
    component: () => import("@/pages/notFound/index.vue"),
  },
  // { path: "*", redirect: "/404" },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
export default router;
